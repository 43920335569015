(function () {
  "use strict";

  /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

  /**
   * Easy on scroll event listener 
   */
  const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
  }

  /**
   * Back to top button
   */
  let backtotop = select('.back-to-top')
  if (backtotop) {
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add('active')
      } else {
        backtotop.classList.remove('active')
      }
    }
    window.addEventListener('load', toggleBacktotop)
    onscroll(document, toggleBacktotop)
  }



  const slideMain = new Swiper(".slide-main", {
    navigation: {
      nextEl: ".next1",
      prevEl: ".prev1",
    },
  });

  const slideHome = new Swiper(".slide-home", {
    slidesPerView: 1,
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },

  });



  
  window.addEventListener('load', () => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })
  });


  
 
  let preloader = select('#preloader');
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.remove()
    });
  }




  $(document).ready(function () {

    // Headroom - show/hide navbar on scroll
    if ($('.headroom')[0]) {
      var headroom = new Headroom(document.querySelector("#header"), {
        offset: 0,
        tolerance: {
          up: 0,
          down: 0
        },
      });
      headroom.init();
    }
  });



  window.addEventListener('DOMContentLoaded', function () {
    ymaps.ready(function () {
      var myMap = new ymaps.Map('map', {
        center: [55.784202, 49.152480],
        zoom: 18,
        controls: ['smallMapDefaultSet']
      }, {

      });

      var myPlacemark = new ymaps.Placemark([55.784202, 49.152480], {
      }, {
        iconLayout: 'default#image',
        iconImageHref: '/local/templates/main/img/svg/geo-alt-fill.svg',
        iconImageSize: [50, 50],
      });


      myMap.geoObjects.add(myPlacemark);

    });

  });


  // window.addEventListener('DOMContentLoaded', function () {

  //   new Cleave('#phone-mask-1', {
  //     numericOnly: true,
  //     blocks: [0, 3, 0, 3, 2, 2],
  //     delimiters: ["+7(", ")", " ", "-"]
  //   });


  
    

  // });




})();
